import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import JobTiles from "./../components/JobTiles";
import { ImagePageLayout } from "./../components/StandalonePageLayout";
import { H4BigStrong } from "./../components/Typography";

const WorkWithUsPage = () => {
  const termsQuery = useStaticQuery(graphql`
    {
      craftWorkWithUsWorkWithUsEntry {
        title
        jobMainPageMainText
        jobMainPageTextIfNoJobsCurrently
        headerImage {
          ... on Craft_editorialAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      allCraftJobsJobEntry {
        nodes {
          title
          expiryDate
          jobClosingDate
          jobShortDescription
          richTextField
          slug
          headerImage {
            ... on Craft_editorialAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 190) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    title,
    jobMainPageMainText,
    jobMainPageTextIfNoJobsCurrently,
  } = termsQuery.craftWorkWithUsWorkWithUsEntry;
  // console.log(termsQuery.craftStandalonePageWorkWithUsPageEntry);
  // console.log(termsQuery);
  const jobs = termsQuery.allCraftJobsJobEntry.nodes;
  return (
    <Layout
      backgroundColor={"var(--purple)"}
      footerColor={"var(--purple)"}
      title={title}
    >
      <ImagePageLayout
        fixedImage
        gatsbyImageImage={
          termsQuery.craftWorkWithUsWorkWithUsEntry.headerImage[0].wideImage
            .childImageSharp.fixed
        }
        topAdjustment={"30px"}
      >
        <h1>{title}</h1>
        <div>
          <div>
            <H4BigStrong>{jobMainPageMainText}</H4BigStrong>
            <JobTiles
              jobs={jobs}
              textIfNoJobs={jobMainPageTextIfNoJobsCurrently}
            />
          </div>
        </div>
      </ImagePageLayout>
    </Layout>
  );
};

export default WorkWithUsPage;
